export const getEvent = (state, eventId) => state.events[eventId];
export const getMedia = (state, mediaId) => state.photos[mediaId];
export const getFilters = (state) => state.filters;
export const getTemplate = (state, mediaId) => state.templates[mediaId];
export const getEnvironment = (state) => state.environment;

/**
 * Retrieves the proposal templates from the store.
 * @param {import('react-redux').RootStateOrAny} state - The current redux state.
 * @returns {import('../types').TemplateData[]} - The proposal templates.
 */
export const getProposalTemplates = (state) => {
  /**
   * @type {string[]} - Array of template IDs from the proposal data (in order)
   */
  const templateIds = state.proposalData?.data?.captureConfig?.modeConfiguration?.photoConfiguration?.printTemplateIds;
  if (!templateIds?.length) {
    return [];
  }
  /**
   * @type {Object<string, import('../types').TemplateData>} - All templates from the store
   */
  const allTemplates = state.proposalOverlays?.data;
  if (!allTemplates) {
    return [];
  }
  // Map template IDs to template objects and filter out any undefined values
  return templateIds.map((id) => allTemplates[id]).filter(Boolean);
};
